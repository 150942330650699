@import "@/styles/globals.scss";
// Fallback theme
@import '../../themes/fallback/main';

// Placeholders
%h1 {
  font-size: 38px;
}

%h2 {
  font-size: 30px;
}

%label {
  font-family: var(--primary-font);
}

.cart .content .ticket header h4,
.content-detail-page .ticket-type-description .title,
.section-header h3 {
  font-family: var(--primary-font);
}
